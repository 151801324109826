<template>
  <v-card
    class="settings-card"
    elevation="3"
    rounded="lg"
  >
    <v-card-title class="text-h5 font-weight-medium">
      <v-icon left color="primary">mdi-truck-delivery</v-icon>
      Third Party Carriers
    </v-card-title>
    
    <v-alert
      v-if="company.data_sources.some(i => i.uses_shopify_labels)"
      type="info"
      class="mx-6 mb-4"
      elevation="2"
      border="left"
      dense
    >
      One or more of your stores are set to use Shopify labels. This section is primarily for stores that don't use Shopify Labels. You do not need to setup additional carriers. Edit settings for Shopify Labels in Stores.
    </v-alert>
    
    <v-card 
      v-for="(carrier, index) in company.carriers.filter(c => c.type !== 'shopify')"
      :key="`carrier-${index}`"
      class="ma-4 pa-4 carrier-card"
      elevation="2"
      rounded="lg"
    >
      <v-row align="center">
        <v-col class="grow">
          <span class="font-weight-medium">{{ carrier.name }}</span>
        </v-col>
        <v-col class="text-right shrink d-flex">
          <v-btn 
            color="error" 
            text 
            rounded
            @click="remove_carrier(carrier.id)"
          >
            <v-icon left>mdi-delete</v-icon>
            Remove
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            rounded
            @click="edit_carrier(carrier.id)" 
            class="ml-2"
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    
    <v-container class="text-center py-6">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            rounded
            elevation="1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Carrier
          </v-btn>
        </template>
        
        <v-card rounded="lg" elevation="4" :loading="saving_carrier">
          <v-form @submit.prevent="save_carrier" ref="carrier_form">
            <v-card-title class="primary white--text">
              <v-row dense>
                <v-col class="grow">{{ editing_carrier ? 'Edit' : 'Add' }} Carrier</v-col>
                <v-col class="shrink">
                  <v-btn
                    icon
                    @click="dialog = false"
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            
            <v-card-text 
              class="pt-4"
              v-if="saving_carrier || editing_carrier || (get_plan && company.carriers.length < get_plan.max_carriers)"
            >
              <v-text-field
                rounded
                outlined
                v-model="new_carrier.name"
                label="Nickname"
                placeholder="ACME Postnord account"
                :rules="[v => !!v || 'Item is required']"
              >
              </v-text-field>
              
              <v-select
                v-model="new_carrier.type"
                @change="new_carrier = { name: new_carrier.name, type: new_carrier.type, id: new_carrier.id, ...carriers[new_carrier.type]}"
                label="Type"
                item-text="display_name"
                item-value="type"
                :items="carrier_types"
                :rules="[v => !!v || 'Item is required']"
                outlined
                rounded
              />
              
              <!-- Carrier-specific fields -->
              <template v-if="new_carrier.type === 'unifaun'">
                <v-text-field
                  rounded
                  outlined
                  v-model="new_carrier.api_key"
                  label="Combined ID"
                  placeholder="E2DRTSZ3WFUIHRSR-UKX2537DPCF2APSAEQKTHN2U"
                  @blur="trim_key"
                  :rules="[v => !!v || 'Item is required',
                          v => v && v.length === 41 || 'Wrong format. Make sure to get the entire combined id which consist of <ID 16 letters>-<SECRET ID 24 letters>']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="number"
                  v-model="new_carrier.sender_id"
                  label="Sender-ID"
                  placeholder="1"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  v-model="new_carrier.contents"
                  label="Default contents"
                  placeholder="ACME T-shirts"
                  :rules="[v => !!v || 'Item is required']"
                />
              </template>
              
              <!-- Other carrier types (fedex, dhl_parcel_de, postnord) -->
              <template v-else-if="new_carrier.type === 'fedex'">
                <!-- Fedex fields -->
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.account_number"
                  label="Fedex Account Number"
                  placeholder="012345678"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.contact.personName"
                  label="Contact Person"
                  placeholder="John Doe"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.contact.companyName"
                  label="Company Name"
                  placeholder="ACME Company"
                />
                <TelInput 
                  v-model="new_carrier.contact.phoneNumber"
                  label="Contact Phone Number"
                  placeholder="+46701112233"
                  required
                  @input="validatePhone"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.address.streetLines[0]"
                  label="Street"
                  placeholder="Lugna gatan 1"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.address.streetLines[1]"
                  label="Street 2"
                  placeholder=""
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.address.city"
                  label="City"
                  placeholder="Stockholm"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.address.postalCode"
                  label="Postal code"
                  placeholder="12345"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.address.countryCode"
                  label="Country Code (2 letters)"
                  placeholder="SE"
                  :rules="[v => !!v || 'Item is required']"
                />
                <template v-if="editing_carrier">
                  <v-card
                    class="mt-4 mb-4 pa-4"
                    outlined
                    rounded="lg"
                  >
                    <v-card-title class="px-0 pt-0 text-subtitle-1 font-weight-medium">
                      <v-icon left color="primary" small>mdi-shield-account</v-icon>
                      FedEx Account Authorization
                    </v-card-title>
                    
                    <v-card-text class="px-0 pb-0">
                      <p class="text-body-2 grey--text text--darken-1 mb-3">
                        To use FedEx services, you need to authorize your account. This is a one-time process.
                      </p>
                      
                      <v-btn
                        color="primary"
                        rounded
                        elevation="1"
                        class="mb-4"
                        @click="on_request_code"
                        :loading="on_requesting_code"
                      >
                        <v-icon left>mdi-key</v-icon>
                        Authorize FedEx Account
                      </v-btn>
                      
                      <template v-if="mfa_options.options">
                        <v-divider class="mb-4"></v-divider>
                        
                        <p class="text-subtitle-2 font-weight-medium mb-2">Request authentication code by:</p>
                        
                        <v-btn-toggle
                          class="mb-4"
                          mandatory
                        >
                          <v-btn
                            v-for="(method) in mfa_options.options.secureCode"
                            :key="method"
                            outlined
                            rounded
                            class="mr-2"
                            @click="on_select_method(method)"
                          >
                            <v-icon left v-if="method === 'EMAIL'">mdi-email</v-icon>
                            <v-icon left v-else>mdi-phone</v-icon>
                            {{ method }}
                            <span class="ml-1 text-caption" v-if="method === 'EMAIL' && mfa_options.email">
                              ({{ mfa_options.email }})
                            </span>
                            <span class="ml-1 text-caption" v-if="method === 'SMS' && mfa_options.phoneNumber">
                              ({{ mfa_options.phoneNumber }})
                            </span>
                          </v-btn>
                        </v-btn-toggle>
                        
                        <v-alert
                          v-if="successfully_sent"
                          type="success"
                          dense
                          elevation="2"
                          border="left"
                          class="mb-4"
                        >
                          Authentication code sent successfully
                        </v-alert>
                        
                        <p class="text-subtitle-2 font-weight-medium mb-2" v-if="successfully_sent">
                          Enter the 6-digit code:
                        </p>
                        
                        <div class="d-flex align-center mb-4">
                          <v-otp-input
                            length="7"
                            type="number"
                            @finish="on_validate_pin"
                            class="flex-grow-1"
                          />
                          <v-progress-circular
                            v-if="on_validating_pin"
                            indeterminate
                            color="primary"
                            size="24"
                            class="ml-4"
                          ></v-progress-circular>
                        </div>
                      </template>
                      
                      <v-alert
                        v-if="new_carrier.auth"
                        type="success"
                        dense
                        elevation="2"
                        border="left"
                        class="mb-2"
                      >
                        <v-icon left small>mdi-check-circle</v-icon>
                        Successfully connected with FedEx
                      </v-alert>
                      
                      <v-alert
                        v-for="e in fedex_errors"
                        :key="e.code"
                        type="error"
                        dense
                        elevation="2"
                        border="left"
                        class="mb-2"
                      >
                        <v-icon left small>mdi-alert-circle</v-icon>
                        {{ e.message }}
                      </v-alert>
                    </v-card-text>
                  </v-card>
                </template>
              </template>
              
              <template v-else-if="new_carrier.type === 'dhl_parcel_de'">
                <!-- DHL fields -->
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.gkp_user"
                  label="DHL Business Customer Portal Username"
                  placeholder="UserName"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="password"
                  v-model="new_carrier.gkp_pass"
                  label="DHL Business Customer Portal password"
                  placeholder=""
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-checkbox
                  v-model="new_carrier.use_shop_name"
                  label="Use shop name as sender name"
                  class="mt-0"
                  ></v-checkbox>
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.shipper.name1"
                  label="Shipper Name"
                  placeholder="ACME Company"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.shipper.addressStreet"
                  label="Street Address"
                  placeholder="Lugna gatan 1"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.shipper.postalCode"
                  label="Postal Code"
                  placeholder="12345"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.shipper.city"
                  label="City"
                  placeholder="Stockholm"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.shipper.country"
                  label="Country Code (3 letters)"
                  placeholder="DEU"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="email"
                  v-model="new_carrier.shipper.email"
                  label="Email"
                  placeholder="contact@example.com"
                  :rules="[v => !!v || 'Item is required']"
                />
                <TelInput 
                  v-model="new_carrier.shipper.phone"
                  label="Phone Number"
                  placeholder="+49701112233"
                  @input="validatePhone"
                />
              </template>
              
              <template v-else-if="new_carrier.type === 'postnord'">
                <!-- Postnord fields -->
                <v-select
                  v-model="new_carrier.issuerCode"
                  label="Postnord customer country *"
                  item-text="name"
                  item-value="id"
                  :items="[
                    {name: 'Postnord Sweden', id: 'Z12'},
                    {name: 'Postnord Denmark', id: 'Z11'},
                    {name: 'Postnord Norway', id: 'Z13'},
                    {name: 'Postnord Finland', id: 'Z14'},
                  ]"
                  :rules="[v => !!v || 'Item is required']"
                  outlined
                  rounded
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.partyIdentification.partyId"
                  label="Postnord customer number *"
                  placeholder="01234567"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.nameIdentification.name"
                  label="Name *"
                  placeholder="John Doe / ACME Company"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.nameIdentification.companyName"
                  label="Company Name"
                  placeholder="ACME Company"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.nameIdentification.careOfName"
                  label="C/O Name"
                  placeholder="Big warehouse"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.address.streets[0]"
                  label="Street *"
                  placeholder="Main road 123"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.address.streets[1]"
                  label="Street 2"
                  placeholder=""
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.address.postalCode"
                  label="Postal Code *"
                  placeholder="12345"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.address.city"
                  label="City *"
                  placeholder="Stockholm"
                  :rules="[v => !!v || 'Item is required']"
                />
                <v-select
                  v-model="new_carrier.party.address.countryCode"
                  label="Country *"
                  item-text="name"
                  item-value="id"
                  :items="[
                    {name: 'Sweden', id: 'SE'},
                    {name: 'Denmark', id: 'DK'},
                    {name: 'Norway', id: 'NO'},
                    {name: 'Finland', id: 'FI'},
                  ]"
                  :rules="[v => !!v || 'Item is required']"
                  outlined
                  rounded
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.contact.contactName"
                  label="Contact name"
                  placeholder="John Doe"
                />
                <v-text-field
                  rounded
                  outlined
                  type="text"
                  v-model="new_carrier.party.contact.emailAddress"
                  label="Contact email"
                  placeholder="john.doe@example.com"
                />
                <TelInput 
                  v-model="new_carrier.party.contact.phoneNo"
                  label="Contact phone"
                  placeholder="+4631123123"
                  @input="validatePhone"
                />
                <TelInput 
                  v-model="new_carrier.party.contact.smsNo"
                  label="Contact SMS Number"
                  placeholder="+46703123123"
                  @input="validatePhone"
                />
                <!-- <v-text-field
                  rounded
                  outlined
                  v-if="$appMode === 'postnord'"
                  v-model="eori_number"
                  label="EORI / customs number"
                /> -->
              </template>
            </v-card-text>
            
            <v-card-text
              v-else
              class="pt-4 text-center"
            >
              <v-alert
                type="warning"
                dense
                elevation="2"
                border="left"
              >
                Your current plan does not allow more carrier accounts to be added. Please upgrade plan.
              </v-alert>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                rounded
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                rounded
                elevation="1"
                type="submit"
                :loading="saving_carrier"
                :disabled="new_carrier.type === 'fedex' && (!new_carrier.auth || !new_carrier.auth.child_key) && !editing_carrier"
                v-if="saving_carrier || editing_carrier || (get_plan && company.carriers.length < get_plan.max_carriers)"
              >
                {{ editing_carrier ? 'Save' : 'Connect' }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { uid } from 'uid'
import TelInput from '@/components/base_components/TelInput'


export default {
  components: {
 
    TelInput
  },
  data(){
    return {
      editing_carrier: false,
      dialog: false,
      connection_error: false,
      saving_carrier: false,
      fedex_errors: [],
      account_auth_token: '',
      mfa_options: {},
      on_requesting_code: false,
      on_validating_pin: false,
      successfully_sent: false,
      new_carrier: {
        name: '',
        type: '',
        id: '',
      },
      carriers: {
        unifaun: {
          api_key: '',
          sender_id: '',
          contents: '',
        },
        dhl_parcel_de: {
          gkp_user: '',
          gkp_pass: '',
          billing_number: '',
          use_shop_name: false,
          "shipper": {
                "name1": "",
                "addressStreet": "",
                "postalCode": "",
                "city": "",
                "country": "",
                "email": "",
                "phone": ""
              },
        },
        fedex: {
          account_number: '',
          "contact": {
            "personName": "",
            "phoneNumber": "",
            "companyName": ""
          },
          "address": {
            "streetLines": [
              "",
              ""
            ],
            "city": "",
            // "stateOrProvinceCode": "",
            "postalCode": "",
            "countryCode": ""
          }
        },
        postnord: {
          "issuerCode": "",
          "partyIdentification": {
            "partyId": "",
            "partyIdType": "160"
          },
          "party": {
            "nameIdentification": {
              "name": "",
              "companyName": "",
              "careOfName": ""
            },
            "address": {
              "streets": [
                "", ""
              ],
              "postalCode": "",
              // "placeName": "string",
              // "state": "string",
              "city": "",
              "countryCode": ""
            },
            "contact": {
              "contactName": "",
              "emailAddress": "",
              "phoneNo": "",
              "smsNo": ""
            }
          }
        },
      },
      carrier_types: [
        { display_name: 'Unifaun / nShift', type: 'unifaun' },
        { display_name: 'Postnord', type: 'postnord' },
        { display_name: 'FedEx', type: 'fedex' },
        { display_name: 'DHL Parcel DE', type: 'dhl_parcel_de' },
      ],
      // eori_number: '',
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    }),
  },
  watch: {
    // new_carrier: function(carrier){
    //   if(
    //     carrier.type === 'fedex' 
    //     // && !this.editing_carrier 
    //     && carrier.address.countryCode 
    //     && carrier.address.streetLines[0]
    //     && carrier.address.postalCode
    //     && carrier.address.city
    //     && carrier.account_number
    //     && carrier.contact.companyName
    //     && !this.account_auth_token
    //   ) this.on_request_code()
    // },
    dialog: function(v){
      if(!v) {
        console.log('watcher')
        this.editing_carrier = false
        this.new_carrier = {
          name: '',
          type: '',
          id: '',
        }
        this.$refs.carrier_form.resetValidation()
        this.connection_error = false
      }
    }
  },
  methods: {
    async on_validate_pin(secureCodePin){
      this.on_validating_pin = true
      const fedex_account_registration = this.$functions.httpsCallable('fedex_account_registration');
      const auth = await fedex_account_registration({
        secureCodePin,
        company_id: this.company.id,
        account_auth_token: this.account_auth_token,
      })
      .then(r => r.data)
      .catch(e => this.fedex_errors = JSON.parse(e.message))
      console.log('auth', auth)
      if(auth?.child_key) {
        this.new_carrier.auth = auth
      }
      this.on_validating_pin = false
    },
    async on_select_method(option){
      const fedex_account_registration = this.$functions.httpsCallable('fedex_account_registration');
      const { status } = await fedex_account_registration({ 
        option, 
        account_auth_token: this.account_auth_token,
        company_id: this.company.id
       }).then(r => {
        return r.data
      })
       .catch(e => this.fedex_errors = JSON.parse(e.message))
       console.log('status', status)
       this.successfully_sent = !!status
    },
    async on_request_code(){
      console.log('on_request_code')
      this.on_requesting_code = true
      const fedex_account_registration = this.$functions.httpsCallable('fedex_account_registration');
      const { mfaOptions }  = await fedex_account_registration({
        ...this.new_carrier,
        company_id: this.company.id
    })
    .then( ({ data }) => data)
    .catch(e => this.fedex_errors = JSON.parse(e.message))
    this.on_requesting_code = false
    console.log(mfaOptions)
    const { accountAuthToken, ...mfa_options } = mfaOptions[0] || {}
    console.log('accountAuthToken', accountAuthToken, mfa_options)
    if(!accountAuthToken) return
    this.account_auth_token = accountAuthToken
    this.mfa_options = mfa_options

    
    },
    trim_key(){
      this.new_carrier.api_key = this.new_carrier.api_key.replace(/ /g, '')
    },
    edit_carrier(id){
      const carrier = this.company.carriers.find(c => c.id === id)
      this.editing_carrier = true
      this.new_carrier = { ...carrier }
      this.dialog = true
    },
    async remove_carrier(id){
      console.log(id);
      const is_used = this.company.data_sources
        .filter(ds => ds.shipping_map
          .some(s => s.sf_carrier_account_id === id)
          )
        .map(ds => ds.name)
      if(is_used.length) return this.$store.commit('app/SET_SNACK_BAR', `This carrier account is being used in shipping maps for ${is_used.toString()}. Remove these shipping routings before removing the carrier account.`)
      const { ok } = await this.$prompt({
        text: 'Are you sure? This action can not be undone.',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'cancel'
          },
        ]
      })
      if(!ok) return 
      const carriers = this.company.carriers
      const index = carriers.findIndex(c => c.id === id)
      if(index === -1) return 
      carriers.splice(index, 1)
      this.$store.commit('warehouse/SET_COMPANY', { carriers })
      this.$store.dispatch('warehouse/save_company', ['carriers'])
    },
    async save_carrier(){
      if(!this.$refs.carrier_form.validate()) return this.$store.commit('app/SET_SNACK_BAR', 'Something is not properly filled out, please scroll up and make sure everything seems correct!')
      this.saving_carrier = true
      // if(this.eori_number) this.update_data_source({ eori_number: this.eori_number })
      const carriers = this.company.carriers
      const id = this.new_carrier.id || uid()
      if(this.new_carrier.id){ carriers[carriers.findIndex(c => c.id === id)] = { ...this.new_carrier } }
      else carriers.push({ ...this.new_carrier, id })
      this.$store.commit('warehouse/SET_COMPANY', { carriers })
      await this.$store.dispatch('warehouse/save_company', ['carriers'])
      
      await this.$store.dispatch('shipping/get_carrier_partners', id)
        .then(() => {
          this.saving_carrier = false
          return this.dialog = false
          })
        .catch(async () => {
          this.saving_carrier = false
          this.$store.commit('app/SET_SNACK_BAR', 'Failed to connect. Please check credentials.')
          const carriers = this.company.carriers.filter(c => c.id !== id)
          this.$store.commit('warehouse/SET_COMPANY', { carriers })
          await this.$store.dispatch('warehouse/save_company', ['carriers'])
        })
    },
    // update_data_source(set){
    //   this.$store.dispatch('warehouse/save_data_source', {
    //     id: this.company.data_sources[0].id,
    //     ...set
    //   })
    // },
    // Phone validation methods
    
    validatePhone(phoneNumber) {
      // Allow empty values (when user clears the field)
      if (!phoneNumber) {
        this.new_carrier.shipper.phone = null;
        return;
      }
      
      if (phoneNumber) {
        if (!phoneNumber.startsWith('+')) {
          this.new_carrier.shipper.phone = '+' + phoneNumber;
        } else {
          this.new_carrier.shipper.phone = phoneNumber;
        }
        
        this.new_carrier.shipper.phone = this.new_carrier.shipper.phone
          .replace(/\s+/g, '')
          .replace(/[().-]/g, '');
      }
    },
    
  },
  mounted(){
    if(this.$route.query.showDialog) this.dialog = true
    else this.dialog = false
    if(process.env.VUE_APP_MODE === 'postnord'){
      this.carrier_types = this.carrier_types.filter(a => a.type === 'postnord')
      // this.eori_number === this.company.data_sources[0].eori_number
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 0.8s ease-out;
  margin-bottom: 24px;
  
  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  // }
  
  .v-card__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }
  
  .v-card__text {
    padding-top: 16px;
  }
}

.carrier-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: 8px !important;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
